import React from "react"
import Pagelayout from "../layouts/page"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import { Col, Row, Container, Button} from "react-bootstrap"
import Aboutlist from "../components/aboutlist"
import Accreditations from "../components/accreditations"
import Clientlist from "../components/clientlist"
import styled from "styled-components"
import Ourteam from "../components/ourteam"

// styles
const Pageheading = styled.h1`
  color: ${({ theme }) => theme.colours.common.black};
  text-align: center;
  font-size: 2.5rem;
  text-transform: uppercase;
  margin: 20px auto;
  font-weight: 400;
  line-height: 1.3;
  font-family: ${({ theme }) => theme.font.headings};

  @media (min-width: 992px) { 
    margin: 10px 0 50px 0;
  }
`

const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.colours.common.white};
  background-color: ${({ theme }) => theme.colours.primary.main};
  border-color: #ba2117;
  font-family: ${({ theme }) => theme.font.headings};
  text-transform: uppercase;
  padding: 5px 20px;

  &:hover, :active, .active, .focus, :focus, :not(:disabled):not(.disabled).active, :not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle  {
    color: ${({ theme }) => theme.colours.common.white};
    background-color: ${({ theme }) => theme.colours.common.black};
    border-color: ${({ theme }) => theme.colours.common.black};
    font-family: ${({ theme }) => theme.font.headings};
    text-transform: uppercase;
    padding: 5px 20px;
  }

  &:focus, :focus {
    box-shadow: 0 0 0 0.2rem ${({ theme }) => theme.colours.primary.light};
  }
`

function Aboutpage ({ data }) {

  return (
    <>
    <Seo title={`${data.directus.settings.site_title} · ${data.directus.home.seo_title}`} description={data.directus.settings.site_description} author={data.directus.settings.site_author}  />
    <Pagelayout logo={data.directus.settings.site_logo} footerimage={data.directus.settings.footer_image} >
        <Row>
          <Col>
                <Pageheading>Who We Are</Pageheading>
          </Col>
        </Row>
        <Aboutlist />
        <Row>
          <Col>
                <Pageheading>Our Team</Pageheading>
          </Col>
        </Row>
        <Ourteam />
        <Row>
          <Col>
                <Pageheading>Our Clients</Pageheading>
          </Col>
        </Row>
        <Clientlist />
        <Row>
          <Col>
                <Pageheading>Accreditations</Pageheading>
          </Col>
        </Row>
        <Accreditations />
    </Pagelayout>
    </>
  )
}


export default Aboutpage

export const query = graphql`
query {
  directus {
    settings {
      site_title
      site_description
      site_author
      site_logo {
        title
        id
        imageFile {
          childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
      footer_image {
        title
        id
        imageFile {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
      contact_details
    }
    home {
      seo_title
      hero_title
      hero_image {
        title
        id
        imageFile {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
      hero_subtitle
      about_heading
      about_image {
        title
        id
        imageFile {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
      accreditation
      professionalism
      projects_heading
      projects_summary
      training
      projects_image {
        title
        id
        imageFile {
          childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
}
`