import React from "react";
import { Container, Col, Row } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

// styles

const Teamtitle = styled.span`
  color: ${({ theme }) => theme.colours.primary.main};
  text-align: center;
  font-weight: 800;
  font-family: ${({ theme }) => theme.font.headings};
  text-transform: uppercase;
  margin: 1em 0 0 0;
  font-size: 24px;
  display: block;

`
const Teamrole = styled.span`
  color: ${({ theme }) => theme.colours.common.black};
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-family: ${({ theme }) => theme.font.headings};
  text-transform: uppercase;
  display: block;
  margin: 0 0 1em 0;
  font-size: 0.9em;

  @media (min-width: 992px) { 
    margin: 1em 0;
    font-size: 14px;
  }

`

const Teamsummary = styled.p`
  color: ${({ theme }) => theme.colours.common.black};
  text-align: center;
  font-size: 0.9em

  @media (min-width: 992px) { 
    font-size: 16px;
  }
`

const Teamrow = styled(Row)`
  margin-bottom: 0px;
  background-color: ${({ theme }) => theme.colours.common.white};
  box-shadow: ${({ theme }) => theme.shadows.shadow1};
  padding: 0px 25px 50px 0;

  @media (min-width: 992px) { 
    padding: 50px 25px;
    margin-bottom: 50px;
  }
`


function Ourteam({ children }) {
 
  const data = useStaticQuery(graphql`
  query {
    directus {
      team(filter: {status: {_eq: "Published"}}) {
        name
        role
        summary
      }
    }
  }
  `)

  const teamNode = data.directus.team;
 
  return (
    
    <>
  <Container>
    <Teamrow className="justify-content-center">
        {teamNode.map((teamItem, i) => 
        (
            <Col xs={12} md={6} lg={4} key={i}>
              <Teamtitle>{teamItem.name}</Teamtitle>
              <Teamrole>{teamItem.role}</Teamrole>
              <Teamsummary>{teamItem.summary}</Teamsummary>
            </Col> ))}        
    </Teamrow>
  </Container>

  </>
  );
}

export default Ourteam;
